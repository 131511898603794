/* Make the image move left to right, in and out of visibility */
@keyframes spinner {
  from {
    left: -25px;
  }
  to {
    left: 100px;
  }
}

/* The element to apply the animation to */
.hell-spinner {
  width: 75px;
  overflow: hidden;
}

.hell-spinner svg {
  position: relative;
  animation-name: spinner;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}