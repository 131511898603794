.text-blue {
  color: $blue;
}
.text-brown {
  color: $orange-800;
}
.text-dark-blue {
  color: $indigo-700;
}
.text-green {
  color: $green;
}
.text-light-blue {
  color: $cyan-200;
}
.text-orange {
  color: $orange;
}
.text-red {
  color: $red;
}
.text-tan {
  color: $yellow-600;
}
.text-yellow {
  color: $yellow;
}