// Override default variables before the import

$font-family-sans-serif: 'Roboto', system-ui, -apple-system, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

h1, h2, h3, h4, h5, h6 {
  font-family: 'Kanit', 'Roboto', sans-serif;
}

h1 {
  font-weight: 600;
}

h2, h3, h4 {
  font-weight: 500;
}

h5, h6 {
  font-weight: 400;
}



// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

// Import our customizations
@import './scss/animations';
@import './scss/text';